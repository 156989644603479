/**
 * Layout component that queries for data
 * with Gatsby's StaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/static-query/
 */

import React, { useEffect } from "react"
import Helmet from "react-helmet"

// import "../css/app.css"
// import "../css/responsive.css"
// import "../css/animation.css"
const addScript = url => {
  const script = document.createElement("script")
  script.src = url
  script.async = true
  document.body.appendChild(script)
}
const Layout = ({ children }) => {
  useEffect(() => {
      // Stage
      addScript(
        "https://stage-footer.mars.com/js/footer.js.aspx?stage-skittlesgummisanctuary-com"
      )
      // Production
      // addScript(
      //   "https://footer.mars.com/js/footer.js.aspx?skittlesgummisanctuary-com"
      // )
  }, [])

  return (
    <>
      {/* <Helmet> */}
      {/* <script
          type="text/javascript"
          src="https://cdn.cookielaw.org/consent/75106614-e1c2-4a5b-acb3-5a8e888f2d73/OtAutoBlock.js"
        ></script> */}

      {/* Stage */}
      {/* <script
          src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
          type="text/javascript"
          charset="UTF-8"
          data-domain-script="d9cf78d8-4415-420a-b9df-bd0b7b4504c0-test"
        ></script> */}

      {/* Production */}
      {/* <script
          src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
          type="text/javascript"
          charset="UTF-8"
          data-domain-script="a86204b2-d719-4c58-ad83-641c82d0d603-test"
        ></script> */}
      {/* </Helmet> */}
      <main>{children}</main>
      {/* {OptanonWrapper} */}
    </>
  )
}

export default Layout
