import React, { useState } from "react"

import AgeCalculator from "../components/agegate-component"

// Background Images - Desktop
import rainbowRight from "../images/rainbow-right.png"
import logoSkittlesGummies from "../images/logo-skittles-gummies.png"
import rainbowTop from "../images/rainbow-top.png"
import cloudsRainbow from "../images/clouds-rainbow.png"
import gummiGreenTop from "../images/gummies/gummi-green-top.png"

const AgeGate = props => {
  const [isOldError, setIsOldError] = useState(false)
  const [isAgeInvalid, setIsAgeInvalid] = useState(false)
  return (
    <>
      <div className="agegate-hldr-parent bg-red">
        <div className="rainbow-top-hldr position-relative">
          <figure className="mb-0 clouds-rainbow position-relative d-md-none">
            <img className="w-100" src={cloudsRainbow} alt="Rainbow" />
          </figure>
          <div className="agegate-hldr position-absolute">
            <figure className="mb-0 position-absolute rainbow-right d-none d-md-block">
              <img
                className="img-fluid"
                src={rainbowRight}
                alt="Rainbow Right"
              />
            </figure>
            <div className="agegate position-relative text-center">
              <figure className="mb-0 position-absolute gummi-green d-md-none">
                <img className="w-100" src={gummiGreenTop} alt="Gummi Green" />
              </figure>
              <figure className="mb-0 position-absolute logo">
                <img
                  className="img-fluid"
                  src={logoSkittlesGummies}
                  alt="Skittles Gummies Logo"
                />
              </figure>

              <h3 className="text-blue d-md-none">
                <span className="d-block">This is a </span>
                <span className="d-block">safe space for you </span>
                <span className="d-block">and gummi animals.</span>
              </h3>

              <h3 className="text-blue d-none d-md-block">
                This is a safe space for you and gummi animals.
              </h3>

              <div className="input-hldr d-flex justify-content-center">
                <AgeCalculator
                  setAgeGateShow={props.setAgeGateShow}
                  setApp={props.setApp}
                  setIsOldError={setIsOldError}
                  setIsAgeInvalid={setIsAgeInvalid}
                />
              </div>

              {isOldError && (
                <p className="large text-red mt-3" style={{ color: "red" }}>
                  Sorry, you do not meet our age requirements
                </p>
              )}
              {isAgeInvalid && (
                <p className="large text-red mt-3" style={{ color: "red" }}>
                  Please enter a valid date of birth.
                </p>
              )}
            </div>
          </div>

          <figure className="mb-0 rainbow-top text-center position-relative d-none d-md-block">
            <img className="w-100" src={rainbowTop} alt="Rainbow Top" />
          </figure>
        </div>
      </div>
    </>
  )
}

export default AgeGate
