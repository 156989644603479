import React, { Component } from "react"
import Cookies from "universal-cookie"
import { navigate } from "gatsby"
// import {Link} from 'gatsby'
//import Success from "../pages/success"
//make years and days array

import { Form, Button } from "react-bootstrap"

function generateArray(start, end) {
  let arr = []
  for (start; start <= end; start++) {
    arr.push(start)
  }
  return arr
}

const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec"
]
//const months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
//1-31
const days = generateArray(1, 31)
//1900-today
const years = generateArray(1900, new Date().getFullYear()).reverse()

//calculate age
function calculateAge(birthday) {
  //milliseconds in a year 1000*24*60*60*365.24 = 31556736000;
  let today = new Date(),
    //birthay has 'Dec 25 1998'
    dob = new Date(birthday),
    //difference in milliseconds
    diff = today.getTime() - dob.getTime(),
    //convert milliseconds into years
    years = Math.floor(diff / 31556736000),
    //1 day has 86400000 milliseconds
    days_diff = Math.floor((diff % 31556736000) / 86400000),
    //1 month has 30.4167 days
    months = Math.floor(days_diff / 30.4167),
    days = Math.floor(days_diff % 30.4167)

  return years
}

let ageCookie = new Cookies()

class AgeCalculator extends Component {
  constructor(props) {
    super(props)
    this.state = {
      day: null,
      month: null,
      year: null,
      age: null,
      ageflag: false
    }

    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleDayChange = this.handleDayChange.bind(this)
    this.handleMonthChange = this.handleMonthChange.bind(this)
    this.handleYearChange = this.handleYearChange.bind(this)
  }

  handleDayChange(e) {
    this.setState({
      day: e.target.value,
      ageflag: false
    })
  }

  handleMonthChange(e) {
    this.setState({
      month: e.target.value,
      ageflag: false
    })
  }

  handleYearChange(e) {
    this.setState({
      year: e.target.value,
      ageflag: false
    })
  }

  handleSubmit(e) {
    e.preventDefault()

    const day = this.state.day,
      month = this.state.month,
      year = this.state.year
    if (month !== null && day !== null && year !== null) {
      let age = calculateAge(`${month} ${day} ${year}`)
      if (age >= 16) {
        console.log("true")
        // if (window.OnetrustActiveGroups === ",1,") {
        ageCookie.set("isOldEnough", "yes", {
          path: "/",
          maxAge: 2592000
          // secure: true,
          // httpOnly: true,
          // sameSite: true,
        })
        // }

        // this.props.setAgeGateShow(false)
        // this.props.setApp(true)
        //this.setState({ ageflag: true })
        navigate("/SkittlesGummies/")
      } else if (
        day === null ||
        day === "DD" ||
        month === null ||
        month === "MM" ||
        year === null ||
        year === "YYYY"
      ) {
        this.props.setIsAgeInvalid(true)
        this.props.setIsOldError(false)
      } else if (age < 16) {
        console.log("false")
        // this.props.setAgeGateShow(true)
        // this.props.setApp(false)
        this.setState({ ageflag: false })
        this.props.setIsOldError(true)
        this.props.setIsAgeInvalid(false)
      }
    } else {
      console.log("false")
      this.setState({ ageflag: false })
      this.props.setIsOldError(true)
      this.props.setIsAgeInvalid(false)
    }
  }

  render() {
    // function refreshPage() {
    //   // window.location.reload(false)
    //   // navigate("/")
    // }
    // function ageError() {
    //   return (
    //     <div>
    //       <span className="error">Below 16Years old are not allowed.</span>
    //     </div>
    //   )
    // }
    return (
      <div>
        <Form onSubmit={this.handleSubmit}>
          <div className="d-flex justify-content-center">
            <Input
              arr={months}
              handleChange={this.handleMonthChange}
              placeholder={"MM"}
              val={this.state.month}
            />
            <Input
              arr={days}
              handleChange={this.handleDayChange}
              placeholder={"DD"}
              val={this.state.day}
            />
            <Input
              arr={years}
              handleChange={this.handleYearChange}
              placeholder={"YYYY"}
              val={this.state.year}
            />
          </div>

          <p className="large text-blue nunito-bold">
            <span className="d-block d-md-inline">Submit your birthday </span>
            <span className="d-block d-md-inline">
              and please control your tastebuds{" "}
            </span>
            <span className="d-block d-md-inline">
              while visiting our gummi sanctuary.
            </span>
          </p>
          <button className="btn nunito-bold text-uppercase">Enter</button>
        </Form>

        {this.state.ageflag && (
          <div>
            <p className="large text-red mt-3">
              Sorry, you do not meet our age requirements.
            </p>
          </div>
        )}
      </div>
    )
  }
}

function Input(props) {
  let strt = [props.placeholder].concat(props.arr)
  let options = strt.map(item => (
    <option value={item} key={item}>
      {item}
    </option>
  ))

  return (
    <Form.Group className="mx-2">
      <Form.Control as="select" onChange={props.handleChange} value={props.val}>
        {options}
      </Form.Control>
      {/* <select onChange={props.handleChange} value={props.val}>
        {options}
      </select> */}
    </Form.Group>
  )
}

export default AgeCalculator
