import React, { useState, useEffect } from "react"
import { navigate } from "gatsby"
import Cookies from "universal-cookie"
import { Helmet } from "react-helmet"

import Layout from "../../components/SkittlesGummiesComponents/components/layout"
import SEO from "../../components/SkittlesGummiesComponents/components/seo"
import Agegate from "../../components/SkittlesGummiesComponents/components/agegate"

const AgegatePage = () => {
  const [ageGateShow, setAgeGateShow] = useState(false)
  let ageCookie = new Cookies()
  useEffect(() => {
    // window.dataLayer = window.dataLayer || []
    if (ageCookie.get("isOldEnough") === "yes") {
      navigate("/SkittlesGummies/")
    } else {
      ageCookie.set("isOldEnough", "no", { path: "/" })
      setAgeGateShow(true)
      // window.dataLayer.push({
      //   event: "virtualPageview",
      //   page: "/agegate",
      // })
    }
  }, [])
  return (
    <>
      <Helmet>
        <link rel="stylesheet" href="../../SkittlesGummies/css/app.css" />
        <link rel="stylesheet" href="../../SkittlesGummies/css/responsive.css" />
        <link rel="stylesheet" href="../../SkittlesGummies/css/animation.css" />
      </Helmet>
      <Layout pageInfo={{ pageName: "agegate" }}>
        <SEO title="Skittles Gummi Sanctuary" />
        {ageGateShow && <Agegate />}
      </Layout>
    </>
  )
}

export default AgegatePage
